/* eslint no-unused-vars: 0 */

import React from "react";
import PropTypes from "prop-types";

const AboutMe = props => {
  const { html, theme } = props;

  return (
    <React.Fragment>
      <body dangerouslySetInnerHTML={{ __html: html }} />

      {/* --- STYLES --- */}
      <style jsx>{`
        :global(ul) {
          list-style: none;
          margin: 0 auto;
          padding: ${`calc(${theme.space.default} * 1.5) 0 calc(${theme.space.default} * 0.5)`};
        }
      `}</style>
    </React.Fragment>
  );
};

AboutMe.propTypes = {
  html: PropTypes.string,
  theme: PropTypes.object.isRequired
};

export default AboutMe;